import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import ChallengeResponse, { Community, CommunityMeasurementResponse } from '../models/game/challengeResponse';
import TeamProgress from '../models/game/teamProgress';
import TeamSettings from '../models/game/teamSettings';
import ChallengeLeaderboardResponse from '../models/game/challengeLeaderboardResponse';
import SyncStatusResponse from '../models/game/syncStatusResponse';
import TeamTargetResponse from '../models/game/teamTargetResponse';
import {KnowledgeBaseInformation} from '../models/knowledgeBaseInformation';
import FinancialOverview from '../models/financialOverview';
import NotificationSettings from '../models/settings/notificationSettings';
import LanguageThemeSettingsResponse, {
  LanguageThemeSettingsPostObject
} from '../models/settings/languageThemeSettingsResponse';
import ResearchSettings, {ResearchUserAnswers} from '../models/settings/researchSettings';
import donorshipTypes from '../models/donorshipTypes';
import UserProfile, {UserProfileReqBody} from '../models/userProfile';
import {nationalVaccinations, nationalVaccines} from '../models/edit-profile/nationalVaccinations';
import travelVaccinations from '../models/edit-profile/travelVaccinations';
import AchievementResponse from '../models/game/achievementResponse';
import {MeasurementType} from '../models/MeasurementType';
import {
  CompetitionAmountResponse,
  CompetitionNotificationSettings,
  CompetitionProgressResponse,
} from '../models/game/competition/competitionAmountProgressResponse';
import PersonalSettings from '../models/game/personalSettings';
import ChallengeDetails from '../models/game/challengeDetails';
import {Allergy, AllergyPostBody} from '../models/edit-profile/allergy';
import GetSuppliersResponse, {GetSupplierAuthorizeUrlResponse} from '../models/settings/supplierResponse';
import AboutResponse from '../models/settings/aboutResponse';
import ComparabilityResponse from '../models/comparability/comparabilityResponse';
import ComparabilityPostBody, {ComparabilityPostResponse} from '../models/comparability/comparabilityPostBody';
import {TimePeriod} from '../models/enums/GraphEnums';
import HistoryFilterOptionsResponse from '../models/history/historyFilterOptionsResponse';
import HistoryPostResponse from '../models/history/historyPostResponse';
import {historyFilterRequest} from '../models/history/historyFilterRequest';
import {ExportHistoryDataBody} from '../models/history/ExportHistoryData';
import DashboardConfigurationSection from '../models/dashboard/DashboardConfigurationSection';
import DashboardResponse, {
  DashboardCaregiverRedirectResponse,
  DashboardCaregiverResponse
} from '../models/dashboard/DashboardResponse';
import SavedNote, {NoteType} from '../models/note/SavedNote';
import NoteFormData from '../models/note/NoteFormData';
import ChallengeSettingsReqBody from '../models/game/ChallengeSettingsReqBody';
import {SavedMeasurement} from '../models/enums/MeasurementEnums';
import MeasurementGraphApiResult from '../models/enums/MeasurementGraphApiResult';
import {MeasurementTypeSelectionResponse} from '../models/dashboard/MeasurementTypeSelectionGroup';
import {UserMeasurementTypeOrderUpdateRequest} from '../models/dashboard/UserMeasurementTypeOrderUpdateRequest';
import PersonnelCompanyInfo from '../models/connectPersonnel';
import SetAuthenticatorResponse from '../models/settings/authenticatorResponse';
import AuthenticatorQrResponse from '../models/settings/authenticatorResponse';
import {SelectedSupplierBody} from "../models/settings/conflictingMeasurementTypeResponse";
import {
  PartnerSupplierCheckAuthorizeResponseResponse,
  PartnerSupplierDisconnectSupplierResponse
} from "../models/settings/partnerSupplierResponses";
import {Store} from "@ngrx/store";
import {GetMeasurementTypeSettingsResponse} from "../models/dashboard/MeasurementTypeSettings";
import {HealthUserAnswers} from "../models/settings/healthSettings";

/** This class handles API calls post login */
@Injectable({providedIn: 'root'})
export class ApiService {
  readonly baseUrl: string;
  readonly healthBaseUrl: string;
  userMeasurementTypeConfiguration: MeasurementTypeSelectionResponse;
  private userId: string;
  private readonly sub: Subscription;

  constructor(
    private http: HttpClient, private store: Store<{ 'userProfile': UserProfile }>) {
    this.baseUrl = environment.apiBffBaseUrl;
    this.healthBaseUrl = environment.apiHealthBaseUrl;
    this.sub = this.store.subscribe(userProfile => {
      if (userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // Get user id post login
  public getUserId(): string | null {
    return this.userId;
  }

  /** DASHBOARD (Series #4) */
  /** DASHBOARD (Series #4) */

  /** DASHBOARD (Series #4) */

  /** Note that this is different from the OIDC user profile. This one contains all user profile data */
  getFullUserProfile(): Promise<UserProfile> {
    return this.http.get<UserProfile>(`${this.baseUrl}user/${this.getUserId()}/profile`).toPromise();
  }

  updateFullUserProfile(userProfile: UserProfileReqBody): Promise<any> {
    return this.http.put<UserProfileReqBody>(`${this.baseUrl}user/${this.getUserId()}/profile`, userProfile).toPromise();
  }

  getAuthenticatorQr(): Promise<AuthenticatorQrResponse> {
    return this.http.get<AuthenticatorQrResponse>(`${this.baseUrl}user/${this.getUserId()}/authenticator-qr`).toPromise();
  }

  getAuthenticator() {
    return this.http.get(`${this.baseUrl}user/${this.getUserId()}/authenticator`);
  }

  disableAuthenticator() {
    return this.http.put(`${this.baseUrl}user/${this.getUserId()}/disable-authenticator`, null).toPromise();
  }

  setAuthenticator(code: string): Promise<SetAuthenticatorResponse> {
    return this.http.post<SetAuthenticatorResponse>(`${this.baseUrl}user/${this.getUserId()}/set-authenticator`, {code: code}).toPromise();
  }

  public getDashboard(): Promise<DashboardResponse> {
    return this.http.get<DashboardResponse>(`${this.baseUrl}dashboard/${this.getUserId()}`).toPromise();
  }

  getContactDashboard(contactId: string): Promise<DashboardResponse> {
    return this.http.get<DashboardResponse>(`${this.baseUrl}dashboard/${this.getUserId()}/contactDashboard/${contactId}`).toPromise();
  }

  public getDashboardSectionConfiguration(): Promise<{ dashboardSections: DashboardConfigurationSection[] }> {
    return this.http.get<{ dashboardSections: DashboardConfigurationSection[] }>(`${this.baseUrl}dashboard/${this.getUserId()}/sectionConfiguration`).toPromise();
  }

  public updateDashboardSectionConfiguration(body: any): Promise<any> {
    return this.http.put(`${this.baseUrl}dashboard/${this.getUserId()}/sectionConfiguration`, body).toPromise();
  }

  public async getUserMeasurementTypeConfiguration(): Promise<MeasurementTypeSelectionResponse> {
    const response = await this.http.get<MeasurementTypeSelectionResponse>(`${this.baseUrl}dashboard/${this.getUserId()}/userMeasurementTypeConfiguration`).toPromise();
    return this.userMeasurementTypeConfiguration = response;
  }

  public saveUserMeasurementTypeConfiguration(measurementTypes: string[], customMeasurementTypeIds: string[]): Promise<any> {
    const url = `${this.baseUrl}dashboard/${this.getUserId()}/userMeasurementTypeConfiguration`;
    return this.http.put<Observable<any>>(url, {
      measurementTypes,
      customMeasurementTypeIds
    }, {observe: 'response'}).toPromise();
  }

  public saveUserMeasurementTypeConfigurationOrder(body: UserMeasurementTypeOrderUpdateRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}dashboard/${this.getUserId()}/userMeasurementTypeConfiguration/order`, body).toPromise();
  }

  public saveCustomMeasurementType(body: any): Promise<any> {
    return this.http.post(`${this.baseUrl}dashboard/${this.getUserId()}/userMeasurementTypeConfiguration/custom`, body).toPromise();
  }

  linkUserToRewards(rewardsInviteToken: string) {
    return this.http.post<any>(`${this.baseUrl}dashboard/${this.getUserId()}/linkUserToRewards`, { tokenId: rewardsInviteToken }).toPromise();
  }

  // note this is dashboard tile: and is different from the one that is triggered from measurement settings
  public deleteDashboardMeasurementTypeTile(measurementTypeId: string, customId?: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}dashboard/${this.getUserId()}/userMeasurementTypeConfiguration/${measurementTypeId}`).toPromise();
  }

  public createNote(formData: NoteFormData): Promise<any> {
    const data = new FormData();
    data.append('Date', formData.date);
    data.append('Type', formData.type);
    if (formData.type === 'Text') {
      if (formData.mood) {
        data.append('Mood', formData.mood);
      }

      data.append('Content', formData.content);
    } else if (formData.type === NoteType.IMAGE || formData.type === NoteType.VOICE) {
      data.append('File', formData.file);
      data.append('Content', formData.content);
    }
    return this.http.post(`${this.baseUrl}dashboard/${this.getUserId()}/note`, data).toPromise();
  }

  public editNote(formData: NoteFormData, id: string): Promise<any> {
    const data = new FormData();
    data.append('Date', formData.date);
    data.append('Type', formData.type);
    if (formData.measuredValue) data.append('MeasuredValue', formData.measuredValue);
    if (formData.mood) data.append('Mood', formData.mood);
    data.append('Content', formData.content);
    if (formData.file) data.append('File', formData.file);

    return this.http.put(`${this.baseUrl}measurement/${this.getUserId()}/note/${id}`, data).toPromise();
  }

  public deleteNote(noteId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}measurement/${this.getUserId()}/note/${noteId}`).toPromise();
  }


  public deleteMeasurement(measurementId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}measurement/${this.getUserId()}/${measurementId}`).toPromise();
  }

  public getNoteByNoteId(noteId: string): Promise<SavedNote> {
    return this.http.get<SavedNote>(`${this.baseUrl}measurement/${this.getUserId()}/note/${noteId}`).toPromise();
  }

  /** DashboardCaregiver */
  /** DashboardCaregiver */
  /** DashboardCaregiver */

  public getDashboardCaregiverSorted(sortBy: string, sortByDescending: boolean, page: number, filterBy: string = undefined): Observable<DashboardCaregiverResponse> {
    let params = new HttpParams();

    if (filterBy) {
      params = params.set('filterBy', filterBy);
    }

    return this.http.get<DashboardCaregiverResponse>(`${this.baseUrl}dashboardCaregiver/${sortBy}/${sortByDescending}/${page}`, { params });
  }

  public dashboardCaregiverAddUser(email: string, cultureCode: string): Promise<any> {
    return this.http.post(`${this.baseUrl}dashboardCaregiver/inviteUser`, {email: email, cultureCode: cultureCode}).toPromise();
  }

  /** Measurement */
  /** Measurement */
  /** Measurement */

  /**
   * Get graph legend data (related to limit values) for a specific measurement type
   * @param {string} measurementTypeId
   * @returns {Promise<any>}
   */
  public getMeasurementTypeLegend(measurementTypeId: string): Promise<any> {
    return this.http.get(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeLegend/${measurementTypeId}`).toPromise();
  }

  /**
   * Get default legend data limit values for a specific measurement type
   * @param {string} measurementTypeId
   * @returns {Promise<any>}
   */
  public getDefaultLimitValuesForMeasurementType(measurementTypeId: string): Promise<any> {
    return this.http.get(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeSettings/${measurementTypeId}/default`).toPromise();
  }

  /**
   * Fetches measurement graph data by interval. Interval is counting backwards.
   * Fetching time period Week, with interval 0 would fetch the current week.
   * Fetching time period Week, with interval 2 would fetch 3 weeks ago.
   * @param {string} userMeasurementTypeId
   * @param {TimePeriod | "CalendarMonth"} interval
   * @param {number} intervalStartingPoint
   * @param customName
   * @param forUserId
   * @param isCaregiver
   * @param contactId
   * @returns {Promise<MeasurementGraphApiResult>}
   */
  public getMeasurementGraphData(userMeasurementTypeId: string, interval: TimePeriod, intervalStartingPoint: number, customName = '', forUserId = '', isCaregiver = false, contactId: string = null): Promise<MeasurementGraphApiResult | any> {
    let params = new HttpParams()
      .set('interval', interval.toString())
      .set('intervalStartingPoint', intervalStartingPoint.toString());

    if (customName && customName.length > 0) {
      params = params.set('customName', customName);
    }

    let userId = this.getUserId();
    if (forUserId && forUserId.length > 0) {
      userId = forUserId;
    }

    if (!isCaregiver && !contactId) {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}measurement/${userId}/forMeasurementType/${userMeasurementTypeId}`, {params: params}).toPromise();
    } else if (contactId) {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}measurement/${userId}/forContact/${contactId}/forMeasurementType/${userMeasurementTypeId}`, {params: params}).toPromise();
    } else {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}dashboardCaregiver/${userId}/forMeasurementType/${userMeasurementTypeId}`, {params: params}).toPromise();
    }
  }

  /**
   * Fetches measurement graph data by a specific date time
   * @param {string} userMeasurementTypeId
   * @param {TimePeriod | "CalendarMonth"} interval
   * @param {string} dateTimeIso
   * @param customName
   * @param forUserId
   * @param isCaregiver
   * @param contactId
   * @returns {Promise<MeasurementGraphApiResult>}
   */
  public getMeasurementGraphDataByDateTime(userMeasurementTypeId: string, interval: TimePeriod, dateTimeIso: string, customName = '', forUserId = '', isCaregiver = false, contactId: string = null): Promise<MeasurementGraphApiResult | any> {
    let params = new HttpParams()
      .set('interval', interval.toString())
      .set('dateTime', dateTimeIso);

    if (customName && customName.length > 0) {
      params = params.set('customName', customName);
    }

    let userId = this.getUserId();
    if (forUserId && forUserId.length > 0) {
      userId = forUserId;
    }

    if (!isCaregiver) {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}measurement/${userId}/forMeasurementType/byDateTime/${userMeasurementTypeId}`, {params: params}).toPromise();
    } else if (contactId) {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}measurement/${userId}/forContact/${contactId}/forMeasurementType/${userMeasurementTypeId}`, {params: params}).toPromise();
    } else {
      return this.http.get<MeasurementGraphApiResult>(`${this.baseUrl}dashboardCaregiver/${userId}/forMeasurementType/byDateTime/${userMeasurementTypeId}`, {params: params}).toPromise();
    }
  }

  public getMeasurementTypeSettings(measurementTypeId: string, forContactId: string = undefined, forUserId: string = undefined): Promise<GetMeasurementTypeSettingsResponse> {
    if (forContactId) {
      return this.http.get<GetMeasurementTypeSettingsResponse>(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeSettings/${measurementTypeId}/contact/${forContactId}`).toPromise();
    } else if (forUserId) {
      return this.http.get<GetMeasurementTypeSettingsResponse>(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeSettings/${measurementTypeId}/patient/${forUserId}`).toPromise();
    } else {
      return this.http.get<GetMeasurementTypeSettingsResponse>(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeSettings/${measurementTypeId}`).toPromise();
    }
  }

  public saveMeasurementTypeSettings(measurementTypeId: string, body: any, customName = ''): Promise<any> {
    let params = new HttpParams();

    if (customName && customName.length > 0) {
      params = params.set('customName', customName);
    }

    return this.http.put(`${this.baseUrl}measurement/${this.getUserId()}/measurementTypeSettings/${measurementTypeId}`, body).toPromise();
  }

  // note this actually deleting measurement type from the account, which is different than 'disabling / deleting' the measurement tile
  public deleteMeasurementType(measurementTypeId: string, customName = ''): Promise<any> {
    return this.http.delete(`${this.baseUrl}measurement/${this.getUserId()}/measurementType/${measurementTypeId}`).toPromise();
  }

  /**
   * Function that adds a 'manual' measurement
   * @param {Date} date
   * @param {number} value
   * @param {string} measurementTypeId
   * @param {string | null} mood
   * @param {string | null} note
   * @param {File | null} file
   * @param customName
   * @returns {Promise<any>}
   */
  public createManualMeasurement(
    date: Date,
    value: number,
    measurementTypeId: string,
    mood: string | null,
    note: string | null,
    file: File | null,
    customName = ''
  ): Promise<any> {
    const formData = new FormData();
    formData.append('Date', date.toISOString());
    formData.append('Value', value.toString());
    formData.append('MeasurementType', measurementTypeId);
    // todo: coordinate with client
    let noteType = 'None';
    if (file) noteType = 'Image';
    else if (note) noteType = 'Text';

    if (mood) {
      formData.append('Mood', mood);
      // allow to send only emoji, without note
      if (noteType === 'None') {
        noteType = 'Text';
      }
    }
    if (note) formData.append('Note', note);
    formData.append('File', file ? file : '');
    formData.append('NoteType', noteType);

    if (customName && customName.length > 0) {
      formData.append('CustomName', customName);
    }

    return this.http.post(`${this.baseUrl}measurement`, formData).toPromise();
  }

  /**
   * Function that edits a measurement
   * @param measurementId
   * @param {Date} date
   * @param {number} value
   * @param {string} measurementTypeId
   * @param {string | null} mood
   * @param {string | null} note
   * @param {File | null} file
   * @param customName
   * @returns {Promise<any>}
   */
  public editManualMeasurement(
    measurementId: string,
    date: Date,
    value: number,
    measurementTypeId: string,
    mood: string | null,
    note: string | null,
    file: File | null,
    customName = ''
  ): Promise<any> {
    const formData = new FormData();
    formData.append('Date', date.toISOString());
    formData.append('Value', value.toString());
    formData.append('MeasurementType', measurementTypeId);
    // note: inform the client that this is confusing as the create is just called Type
    let noteType = 'None';
    if (file) noteType = 'Image';
    else if (note) noteType = 'Text';
    if (mood) {
      formData.append('Mood', mood);
      // allow to send only emoji, without note
      if (noteType === 'None') {
        noteType = 'Text';
      }
    }
    if (note) formData.append('Note', note);
    if (file) formData.append('File', file);
    formData.append('NoteType', noteType);

    if (customName && customName.length > 0) {
      formData.append('CustomName', customName);
    }

    return this.http.put(`${this.baseUrl}measurement/${this.getUserId()}/${measurementId}`, formData).toPromise();
  }

  /**
   * Function that gets a measurement by it's id
   * @param {Date} date
   * @param {number} value
   * @param {string} measurementTypeId
   * @param {string | null} mood
   * @param {string | null} note
   * @param {File | null} file
   * @returns {Promise<any>}
   */
  public getMeasurementById(measurementId: string): Promise<SavedMeasurement> {
    return this.http.get<SavedMeasurement>(`${this.baseUrl}measurement/${this.getUserId()}/${measurementId}`).toPromise();
  }

  public reactivateSupplier(supplier: string): Promise<any>{
    return this.http.post(`${this.baseUrl}supplier/${this.getUserId()}/reactivate/supplier/${supplier}`, null).toPromise();
  }

  /** HISTORY (series #6) */

  /** HISTORY (series #6) */
  /** HISTORY (series #6) */
  getFilteredHistoryData(body: historyFilterRequest): Promise<HistoryPostResponse> {
    return this.http.post<HistoryPostResponse>(`${this.baseUrl}measurement/${this.getUserId()}/history`, body).toPromise();
  }

  getHistoryFilterOptionsData(): Promise<HistoryFilterOptionsResponse> {
    return this.http.get<HistoryFilterOptionsResponse>(`${this.baseUrl}measurement/${this.getUserId()}/history/filter`).toPromise();
  }

  putFavorite(favorite: boolean, itemType: string, historyId: string): Promise<any> {
    return this.http.put<HistoryPostResponse>(`${this.baseUrl}measurement/${this.getUserId()}/favorite/${itemType}/${historyId}`, {favorite: favorite}).toPromise();
  }

  exportHistoryData(body: ExportHistoryDataBody) {
    return this.http.post<ExportHistoryDataBody>(`${this.baseUrl}measurement/${this.getUserId()}/export/`, body).toPromise();
  }

  /** GAMES (series #7) */
  /** GAMES (series #7) */

  /** GAMES (series #7) */

  /** CHALLENGES */

  userHasActiveChallenge(): Promise<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}challenge/hasActiveChallenge/${this.getUserId()}`).toPromise();
  }

  public getPersonnelCompanyInfo(companyId: string, personnelId: string): Promise<PersonnelCompanyInfo> {
    return this.http.get<PersonnelCompanyInfo>(`${this.baseUrl}challenge/personnelCompanyInfo/${companyId}/${personnelId}`).toPromise();
  }

  getGameChallenge(): Promise<ChallengeResponse> {
    return this.http.get<ChallengeResponse>(`${this.baseUrl}challenge/${this.getUserId()}`).toPromise();
  }

  getMyCommunities(): Promise<Community[]> {
    return this.http.get<Community[]>(`${this.baseUrl}community?userId=${this.getUserId()}`).toPromise();
  }

  getMyCommunityMeasurements(communityId: number, measurementTypes: string[], dayRange: number) : Promise<CommunityMeasurementResponse> {
    const mappedMeasurementTypes = measurementTypes.map((param) => {
      return(
       'measurementTypes=' + param
      );
    }).join('&');
    const url = `${this.baseUrl}community/${communityId}?userId=${this.getUserId()}&dayRange=${dayRange}&${mappedMeasurementTypes}`;
    return this.http.get<CommunityMeasurementResponse>(url).toPromise();
  }

  startGameChallenge(body: { nickname: string; dailyTarget: number; teamIncentiveId: string }) {
    return this.http.put(`${this.baseUrl}challenge/${this.getUserId()}/start/${body.teamIncentiveId}`, null, {
      params: {
        personalGoal: body.dailyTarget.toString(),
        nickname: body.nickname.toString()
      }
    });
  }

  quitGameChallenge(teamId: string) {
    return this.http.put(`${this.baseUrl}challenge/${this.getUserId()}/quit/${teamId}`, null).toPromise();
  }

  getTeamProgress(teamId: string): Promise<TeamProgress> {
    return this.http.get<TeamProgress>(`${this.baseUrl}challenge/${this.getUserId()}/team/${teamId}`).toPromise();
  }

  getTeamSettings(teamId: string): Promise<TeamSettings> {
    return this.http.get<TeamSettings>(`${this.baseUrl}challenge/${this.getUserId()}/team/${teamId}/settings`).toPromise();
  }

  getPersonalSettings(): Promise<PersonalSettings> {
    return this.http.get<PersonalSettings>(`${this.baseUrl}challenge/${this.getUserId()}/personal/settings`).toPromise();
  }

  getChallengeLeaderBoard(): Promise<ChallengeLeaderboardResponse> {
    return this.http.get<ChallengeLeaderboardResponse>(`${this.baseUrl}challenge/${this.getUserId()}/leaderboard`).toPromise();
  }

  getChallengeSyncStatus(teamId: string): Promise<SyncStatusResponse> {
    return this.http.get<SyncStatusResponse>(`${this.baseUrl}challenge/${this.getUserId()}/team/${teamId}/syncStatus`).toPromise();
  }

  getChallengePersonalSyncStatus(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}challenge/${this.getUserId()}/personal/syncStatus`).toPromise();
  }

  getTeamTarget(teamId: string): Promise<TeamTargetResponse> {
    return this.http.get<TeamTargetResponse>(`${this.baseUrl}challenge/${this.getUserId()}/team/${teamId}/target`).toPromise();
  }

  getPersonalTarget(): Promise<TeamTargetResponse> {
    return this.http.get<TeamTargetResponse>(`${this.baseUrl}challenge/${this.getUserId()}/personal/target`).toPromise();
  }

  exportChallengeDetails(): Promise<string> {
    return this.http.get<string>(`${this.baseUrl}challenge/${this.getUserId()}/details/export`).toPromise();
  }

  setChallengeProgressMails(receiveProgressMails: boolean): Observable<any> {
    return this.http.put(`${this.baseUrl}challenge/${this.getUserId()}/personal/settings/progressMails`, {receiveProgressMails});
  }

  savePersonalChallengeSettings(body: ChallengeSettingsReqBody): Observable<any> {
    return this.http.put(`${this.baseUrl}challenge/${this.getUserId()}/personal/settings`, body);
  }

  /** Challenge details (history) tab */
  getChallengeDetails(): Promise<ChallengeDetails> {
    return this.http.get<ChallengeDetails>(`${this.baseUrl}challenge/${this.getUserId()}/details`).toPromise();
  }

  /** ACHIEVEMENTS */
  getAchievementData(): Promise<AchievementResponse> {
    return this.http.get<AchievementResponse>(`${this.baseUrl}achievement/${this.getUserId()}`).toPromise();
  }

  /** COMPETITION */

  getCompetitionAmount(measurement: MeasurementType): Promise<CompetitionAmountResponse> {
    const data = {
      measurementType: measurement.id
    };
    return this.http.post<CompetitionAmountResponse>(`${this.baseUrl}competition/${this.getUserId()}/amount`, data).toPromise();
  }

  getCompetitionProgress(measurement: MeasurementType): Promise<CompetitionProgressResponse> {
    const data = {
      measurementType: measurement.id
    };
    return this.http.post<CompetitionProgressResponse>(`${this.baseUrl}competition/${this.getUserId()}/progress`, data).toPromise();
  }

  getCompetitionNotificationSettings(): Promise<CompetitionNotificationSettings> {
    return this.http.get<CompetitionNotificationSettings>(`${this.baseUrl}competition/${this.getUserId()}/notificationSettings`).toPromise();
  }

  updateCompetitionNotificationSettings(body: CompetitionNotificationSettings): Promise<CompetitionNotificationSettings> {
    return this.http.put<CompetitionNotificationSettings>(`${this.baseUrl}competition/${this.getUserId()}/notificationSettings`, body).toPromise();
  }

  /** COMPARABILITY */
  postComparability(filterSet: ComparabilityPostBody): Promise<ComparabilityPostResponse> {
    return this.http.post<ComparabilityPostResponse>(`${this.baseUrl}comparability/${this.getUserId()}`, filterSet).toPromise();
  }

  getComparabilityMeasurements(): Promise<ComparabilityResponse> {
    return this.http.get<ComparabilityResponse>(`${this.baseUrl}comparability/${this.getUserId()}/filter`).toPromise();
  }

  saveComparabilityFilter(): Promise<ComparabilityResponse> {
    return this.http.get<ComparabilityResponse>(`${this.baseUrl}comparability/${this.getUserId()}/filter`).toPromise();
  }

  /** SETTINGS (series #9) */
  /** SETTINGS (series #9) */

  /** SETTINGS (series #9) */

  /** NOTIFICATIONS */
  getNotificationSettings(): Promise<NotificationSettings> {
    return this.http.get<NotificationSettings>(`${this.baseUrl}setting/${this.getUserId()}/notification`).toPromise();
  }

  setNotificationSettings(notificationSettings: NotificationSettings): Observable<NotificationSettings> {
    // Note that right now the api doesn't accept the alerts property yet. When this is updated: todo when api ready: pass in @notificationSettings instead of object
    const object = {
      newsletter: notificationSettings.newsletter,
      coachMessages: notificationSettings.coachMessages,
      coach: notificationSettings.coach
    };
    return this.http.put<NotificationSettings>(`${this.baseUrl}setting/${this.getUserId()}/notification`, object);
  }

  // This separate call is taken from setNotificationSettings since this is only for enabling notifications(setNotificationSettings expect a NotificationSettings instance)
  enableNotifications() {
    if (!(window as any).webkit.messageHandlers.NativeApp) {
      (window as any).webkit.messageHandlers.NativeApp = (window as any).NativeApp;
    }
    (window as any).webkit.messageHandlers.NativeApp.postMessage({
      action: 'notifications.authorize',
      source: 'notifications'
    });

    const object = {
      newsletter: true,
      // Todo when api ready: add alerts property.
    };
    return this.http.put<{ newsletter: boolean }>(`${this.baseUrl}setting/${this.getUserId()}/notification`, object);
  }


  /** USER */

  public putConnectPersonnel(personnelId: string, hash: string) {
    const body = {personnelId, hash};
    return this.http.put(`${this.baseUrl}user/${this.getUserId()}/connectPersonnel`, body).toPromise();
  }

  public getSelectableDonorshipTypes(): Promise<donorshipTypes> {
    return this.http.get<donorshipTypes>(`${this.baseUrl}user/${this.getUserId()}/donorshipTypes`).toPromise();
  }

  public updateDonorShips(donorshipTypeIds: { donorshipTypeIds: string[] }): Promise<any> {
    return this.http.put<{ donorshipTypeIds: string[] }>(`${this.baseUrl}user/${this.getUserId()}/profile/donorship`, donorshipTypeIds).toPromise();
  }

  public userUpdateProfilePicture(file?: string | File): Promise<any> {
    const data = new FormData();
    data.append('profilePicture', file);

    return this.http.put(`${this.baseUrl}user/${this.getUserId()}/profile/picture`, data).toPromise();
  }

  /** SUPPLIER */

  public getSuppliers(): Promise<GetSuppliersResponse> {
    return this.http.get<GetSuppliersResponse>(`${this.baseUrl}supplier/${this.getUserId()}`).toPromise();
  }

  public getSupplierAuthorizeUrl(supplierId: string): Promise<GetSupplierAuthorizeUrlResponse> {
    return this.http.get<GetSupplierAuthorizeUrlResponse>(`${this.baseUrl}supplier/${this.getUserId()}/${supplierId}/authorizeUrl`).toPromise();
  }

  public checkAuthorizeResponse(supplierId: string, supplierQueryParameters: string): Promise<any> {
    const body = {supplierQueryParameters: supplierQueryParameters};
    return this.http.post(`${this.baseUrl}supplier/${this.getUserId()}/${supplierId}/checkAuthorizeResponse`, body).toPromise();
  }

  public disconnectSupplier(supplier: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}supplier/${this.getUserId()}/${supplier}`).toPromise();
  }

  /** PARTNERSUPPLIER */

  public getSupplierAuthorizeUrlPartner(partner: string, supplierId: string): Promise<GetSupplierAuthorizeUrlResponse> {
    return this.http.get<GetSupplierAuthorizeUrlResponse>(`${this.baseUrl}partner/supplier/${partner}/${supplierId}/authorizeUrl`).toPromise();
  }

  public checkAuthorizeResponsePartner(partner: string, supplierId: string, supplierQueryParameters: string): Promise<PartnerSupplierCheckAuthorizeResponseResponse> {
    const body = {supplierQueryParameters: supplierQueryParameters};
    return this.http.post<PartnerSupplierCheckAuthorizeResponseResponse>(`${this.baseUrl}partner/supplier/${partner}/${supplierId}/checkAuthorizeResponse`, body).toPromise();
  }

  public disconnectSupplierPartner(partner: string, supplier: string): Promise<PartnerSupplierDisconnectSupplierResponse> {
    return this.http.delete<PartnerSupplierDisconnectSupplierResponse>(`${this.baseUrl}partner/supplier/${partner}/${supplier}`).toPromise();
  }

  /** LANGUAGE AND THEME */

  getLanguageThemeSettings(): Promise<LanguageThemeSettingsResponse> {
    return this.http.get<LanguageThemeSettingsResponse>(`${this.baseUrl}setting/${this.getUserId()}/userPreference`).toPromise();
  }

  setLanguageThemeSettings(languageThemeSettings: LanguageThemeSettingsPostObject) {
    return this.http.put<LanguageThemeSettingsPostObject>(`${this.baseUrl}setting/${this.getUserId()}/userPreference`, languageThemeSettings);
  }

  /** Research */
  /** Research */
  /** Research */

  putResearchAnswers(researchId: string, researchUserAnswers: ResearchUserAnswers) {
    return this.http.put<ResearchUserAnswers>(`${this.baseUrl}research/${researchId}/answers`, researchUserAnswers);
  }

  public setAnswer(researchUserAnswers: HealthUserAnswers, caregiverId: string) {
    return this.http.put<HealthUserAnswers>(`${this.baseUrl}research/${caregiverId}/${this.userId}/answers`, researchUserAnswers);
  }

  /** KNOWLEDGE BASE */

  getKnowledgeBaseInformation(): Promise<KnowledgeBaseInformation> {
    return this.http.get<KnowledgeBaseInformation>(`${this.baseUrl}knowledgeBase/${this.getUserId()}`).toPromise();
  }

  /** FINANCIAL */

  getFinancialOverview(): Promise<FinancialOverview> {
    return this.http.get<FinancialOverview>(`${this.baseUrl}finance/${this.getUserId()}/overview`).toPromise();
  }

  savePromoCode(code: string) {
    const jsonPut = {
      code: code
    };
    return this.http.put(`${this.baseUrl}finance/${this.getUserId()}/activateVoucher`, jsonPut).toPromise();
  }

  unlinkFromCustomer(id: number) {
    return this.http.delete(`${this.baseUrl}finance/${this.getUserId()}/unlinkCustomer/${id}`).toPromise();
  }

  revokePaymentAuthorization() {
    return this.http.delete(`${this.baseUrl}finance/${this.getUserId()}/revokePaymentAuthorization`);
  }

  downloadInvoice(id: string) {
    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream'
      })
    };

    return this.http.get(`${this.baseUrl}finance/${this.getUserId()}/downloadInvoice/${id}`, options);
  }

  /** ABOUT */
  getAboutData(): Promise<AboutResponse> {
    return this.http.get<AboutResponse>(`${this.baseUrl}setting/${this.getUserId()}/about`).toPromise();
  }

  /** Vaccines */
  getSelectableNationalVaccines(): Promise<nationalVaccines> {
    return this.http.get<nationalVaccines>(`${this.baseUrl}vaccination/${this.getUserId()}/national/vaccines`).toPromise();
  }

  getUserNationalVaccinations(): Promise<nationalVaccinations> {
    return this.http.get<nationalVaccinations>(`${this.baseUrl}vaccination/${this.getUserId()}/national`).toPromise();
  }

  saveNationalVaccine(jsonPost: { vaccineId: string; date: string }) {
    return this.http.post(`${this.baseUrl}vaccination/${this.getUserId()}/national`, jsonPost);
  }

  getUserTravelVaccine(): Promise<travelVaccinations> {
    return this.http.get<travelVaccinations>(`${this.baseUrl}vaccination/${this.getUserId()}/travel`).toPromise();
  }

  saveTravelVaccine(name: string, frequency: number, date: string, validity: number, doses: string, brand: string, expiration: string) {
    const jsonPost = {
      name: name,
      frequency: frequency,
      date: date,
      validity: validity,
      dosis: doses,
      brand: brand,
      expiration: expiration
    };
    return this.http.post<void>(`${this.baseUrl}vaccination/${this.getUserId()}/travel`, jsonPost).toPromise();
  }

  /** ALLERGIES */
  getUserAllergies(): Promise<{ allergies: Allergy[] }> {
    return this.http.get<{ allergies: Allergy[] }>(`${this.baseUrl}allergy/${this.getUserId()}/overview`).toPromise();
  }

  getAllergy(allergyId: string): Promise<Allergy> {
    return this.http.get<Allergy>(`${this.baseUrl}allergy/${this.getUserId()}/${allergyId}`).toPromise();
  }

  removeAllergy(allergyId: string) {
    return this.http.delete(`${this.baseUrl}allergy/${this.getUserId()}/${allergyId}`);
  }

  editAllergy(allergyData: Allergy) {
    return this.http.put(`${this.baseUrl}allergy/${this.getUserId()}/${allergyData.id}`, allergyData);
  }

  addAllergy(allergyPostBody: AllergyPostBody) {
    return this.http.post(`${this.baseUrl}allergy/${this.getUserId()}`, allergyPostBody);
  }

  /** NETWORK */

  // todo: note: purely done by socket atm
  // todo: note: purely done by socket atm
  // todo: note: purely done by socket atm

  /** MESSAGES */

  createChatOrDefault(targetUserId: string): Promise<string> {
    return this.http.get<string>(`${this.baseUrl}chat/createordefault/${targetUserId}`).toPromise();
  }

  getConflictingMeasurementTypes(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}supplier/${this.getUserId()}/conflictingMeasurementTypes`).toPromise();
  }

  setConflictingMeasurementTypes(measurementTypeSources: SelectedSupplierBody): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}supplier/${this.getUserId()}/conflictingMeasurementTypes`, measurementTypeSources).toPromise();
  }

  linkUserToChallenge(tokenId: string) {
    return this.http.put<any>(`${this.baseUrl}challenge/${this.getUserId()}/linkUserToChallenge`, { tokenId: tokenId }).toPromise();
  }

  // getRoomMessages(roomId: string) {
  //   return this.http.get<MessagesResponse>(`${this.baseUrl}chat/${this.getUserId()}/room/${roomId}`).toPromise();
  // }
  //
  // getRoomMessagesFromDate(roomId: string, isoDateString: string) {
  //   return this.http.get<MessagesResponse>(`${this.baseUrl}chat/${this.getUserId()}/room/${roomId}/${isoDateString}`).toPromise();
  // }
  //
  // getRooms() {
  //   return this.http.get<RoomsResponse>(`${this.baseUrl}chat/${this.getUserId()}/rooms`).toPromise();
  // }
  //
  // postMessage(body: MessagePostBody) {
  //   return this.http.post(`${this.baseUrl}api/chat/${this.getUserId()}/message`, body);
  // }
  //
  // deleteMessage(messageId: string) {
  //   return this.http.delete(`${this.baseUrl}chat/${this.getUserId()}/message/${messageId}`);
  // }
  //
  // deleteRoom(roomId: string) {
  //   return this.http.delete(`${this.baseUrl}chat/${this.getUserId()}/room/${roomId}`);
  // }

  /** CONNECTIONS */

  // getCommunityMembers(): Promise<CommunityMembersResponse> {
  //   return this.http.get<CommunityMembersResponse>(`${this.baseUrl}community/${this.getUserId()}/members`).toPromise();
  // }
  //
  // inviteByEmail(email: string) {
  //   // const igor = 'igor_willems@outlook.com';
  //   const igor2 = 'vibonacci@outlook.com';
  //   const body = {
  //     email: igor2,
  //     // email: igor,
  //   };
  //   return this.http.post(`${this.baseUrl}community/${this.getUserId()}/member`, body).toPromise();
  // }
  //
  // // Not sure what should be included in the body since the memberId is given inside the url
  // declineInvitation(memberId: string) {
  //   return this.http.put(`${this.baseUrl}community/${this.getUserId()}/declineInvitation/${memberId}`, '');
  // }
  //
  // // Not sure what should be included in the body since the memberId is given inside the url
  // revokeInvitation(memberId: string) {
  //   return this.http.put(`${this.baseUrl}community/${this.getUserId()}/revokeInvitation/${memberId}`, '');
  // }
  //
  // getMemberPermissions(memberId: string): Promise<MemberPermissionsResponse> {
  //   return this.http.get<MemberPermissionsResponse>(`${this.baseUrl}community/${this.getUserId()}/permissions/${memberId}`).toPromise();
  // }
  //
  // setMemberPermissions(body: SetPermissionsBody) {
  //   return this.http.put(`${this.baseUrl}community/${this.getUserId()}/permissions/${body.memberId}`, body);
  // }
  //
  // removeConnection(memberId: string) {
  //   return this.http.delete(`${this.baseUrl}community/${this.getUserId()}/member/${memberId}`);
  // }
}


